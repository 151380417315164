import React from 'react';
import './phone.css';
import cn from '../../utils/classnames';

class Phone extends React.Component {
  render () {
    const { className, theme, withBorder=true, children } = this.props; 
    return (
      <>
        <div className={cn("phone", theme && `phone--${theme}`, className)} ref={el => this.el = el}>
          <div 
            className="phone__screen"
          >
            {children}
            {/* <div 
              className="phone__progress"
              style={{width: `${(this.state.currentIndex + 1)/screenshots.length* 100}%`}}
            ></div> */}
          </div>
        </div>
      </>
    )
  }
} 

export default Phone;