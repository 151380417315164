import React from 'react';
import cn from '../utils/classnames';
import './details.css';

class Details extends React.Component {
  state = {
    active: this.props.defaultActive || false
  }

  // static getDerivedStateFromProps({ nextActive }, { active, ...prevState }) {
  //   if (nextActive === active) {
  //     return null;
  //   }
  //   return {
  //     ...prevState,
  //     active: nextActive
  //   }
  // }

  handleToggle = () => {
    this.setState(prev => ({active: !prev.active}));
  }

  render() {
    const { title, children, className, defaultActive, linkClass="black-pseudo", linkStyle={fontWeight: 700}, ...props} = this.props;
    const { active } = this.state;
    return (
      <div className={cn(`details`, active && `details--active`, className)} {...props}>
        <a className={cn("details__link", linkClass)} onClick={this.handleToggle} style={linkStyle}>{title}</a>
        <div className="details__content">
          {children}
        </div>
      </div>
    );
  }
}

export default Details;