import React from 'react'
import Layout from './layout'
import Img from 'gatsby-image'
import SEO from './seo'
import Wrapper from './wrapper'
import Phone from './Phone'
import Section from './section'
import locals from '../images/locals@2x.png'
import loadScript from '../utils/loadScript'
import Video from './Video'
import Review from './Review'
import cn from '../utils/classnames'
import Button from './Button'
import Header from './header'
// import Popup from './Popup';
import howToVideo from '../video/how-to.mp4'
import howToPoster from '../video/how-to-poster.png'
import initSmoothscroll from '../utils/initSmoothscroll'
import Screencast from './Screencast'

const WIDGET_SCRIPT = 'https://app.surprizeme.ru/static/js/widgetManager.js'
const APP_STORE =
  'https://redirect.appmetrica.yandex.com/serve/530307184148360178'
const GOOGLE_PLAY =
  'https://redirect.appmetrica.yandex.com/serve/314134426227351778'

const reinitWidgetManager = () => {
  if (!window.surprisemeWidgetManager) return

  window.surprisemeWidgetManager.reinit()
}

class IndexPage extends React.Component {
  state = {
    showPopup: false,
  }

  componentDidMount() {
    const { collections = [] } = this.props
    if (!collections.length) return

    loadScript(WIDGET_SCRIPT)
    setTimeout(() => {
      reinitWidgetManager()
    }, 1000)

    reinitWidgetManager()

    initSmoothscroll()
  }

  componentDidUpdate(prevProps, prevState) {
    reinitWidgetManager()
  }

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    })
  }

  render() {
    const {
      gatsbyCover,
      heading,
      title = heading,
      description = null,
      lead = 'Surprise Me превратит ваш телефон в персонального гида',
      videos,
      gifs,
      posters,
      reviews,
      children,
      action,
    } = this.props
    return (
      <Layout>
        <SEO title={title} description={description} lang="ru" />
        <Wrapper className="white1">
          {/* <Header
            siteTitle={'Surprise Me'}
            className="wrapped hidden-sm NanoMarged--xs center-xs"
            withIcon={true}
          /> */}
          <Section className="container1 white-section TopMarged TopNanoMarged--xs">
            {/* {gatsbyCover && (
            <Img
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              imgStyle={{ width: '100%', height: '100%' }}
              className="shadowed"
              resolutions={{
                ...gatsbyCover.fluid,
                base64: gatsbyCover.sqip.dataURI,
              }}
            />
          )} */}
            {/* TODO: use gatsby image inside tilt */}
            {/* <Tilt src={cover} /> */}
            {/* <div className="content"> */}
            <div className="row middle-sm between-sm">
              <div
                className="col-xs-12 col-sm-6 start-md center-xs"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Header
                  siteTitle={'Surprise Me'}
                  theme="white1"
                  withIcon={true}
                />
                <div className="DoubleMarged NoMarged--xs TopMarged--sm">
                  <h1 className="hero-heading">{heading}</h1>
                  <p className="lead Marged">{lead}</p>
                  {action}
                </div>
                {/* <div>
                    <a 
                      className="with-icon link-with-play light" 
                      onClick={this.togglePopup}
                    >
                      <span>
                        Смотреть видео: как это работает за 15 секунд
                      </span>
                    </a>
                  </div> */}

                {/* {this.state.showPopup ? (
                    <Popup closePopup={this.togglePopup}>
                      <Video 
                        src={howToVideo} 
                        poster={null} 
                        contain
                      />
                    </Popup>
                  ) : null} */}
              </div>
              <div className="col-xs-12 col-sm-5 phone-col phone-col--right">
                <Phone theme="dark" withBorder={false} className="ph">
                  <Video src={howToVideo} poster={howToPoster} />
                </Phone>
              </div>
            </div>
            {/* </div> */}
          </Section>
        </Wrapper>
        <Wrapper id="how">
          <Section className="blue-section">
            <div className="row middle-sm between-sm">
              <div className="col-xs-12 col-sm-7 Marged">
                <h2>Приложение будет вашим личным гидом</h2>
                <p className="lead">
                  Вы пройдете маршрут шаг за шагом, вас ждёт насыщенный рассказ
                  о городе, иллюстрации и задания на внимательность и эрудицию
                </p>
                {/* <p className="lead">
                  Вам не нужен интернет или карты – с&nbsp;помощью GPS
                  приложение проведет вас по&nbsp;маршруту от локации к локации
                  и не даст заблудиться.
                </p> */}
              </div>
              <div className="col-xs-12 col-sm-5 phone-col phone-col--left first-sm">
                <Phone className="phone--white-notch-bg">
                  <Screencast src={gifs.geo} poster={posters && posters.geo} />
                  {/* <Video 
                    src={videos.geo} 
                    poster={posters && posters.geo} 
                    loop={true}
                  /> */}
                </Phone>
              </div>
            </div>
          </Section>
          <Section className="wrapped NanoMarged">
            <div className="row middle-sm between-sm">
              <div className="col-xs-12 col-sm-7 NanoMarged">
                <h2>Город глазами местных</h2>
                <p className="lead">
                  Экскурсии составили местные эксперты: энтузиасты, гиды,
                  архитекторы и блогеры
                </p>
                <p className="lead" />
              </div>
              <div className="col-xs-12 col-sm-5 phone-col phone-col--left first-sm NanoMarged">
                {/* <Image style={{width: `100%`}} /> */}
                <img className="locals antiwrapped--xs" src={locals} />
              </div>
            </div>
          </Section>
          <Section className="wrapped">
            <div className="row between-md">
              <div className="col-xs-12 col-sm-4">
                {/* <span className="emoji">🌃🌇</span> */}
                <h3>Начинайте в удобное время</h3>
                <p>
                  У экскурсии нет фиксированной даты и времени, гуляйте
                  с мобильным гидом даже ночью
                </p>
              </div>
              {/* <div className="col-xs-12 col-sm-4 NanoMarged">
                <h3>Можно ставить на паузу</h3>
                <p>
                  Никто не поторапливает. Устали ходить — сделайте паузу,
                  прогресс сохранится, продолжайте с того же места когда
                  захотите
                </p>
              </div> */}
              <div className="col-xs-12 col-sm-4">
                {/* <span className="emoji">🌟</span> */}
                <h3>Проверенные экскурсии</h3>
                <p>
                  Наш редактор модерирует каждую экскурсию в магазине
                  и&nbsp;помогает авторам рассказать факты и&nbsp;истории самым
                  интересным образом
                </p>
              </div>
              <div className="col-xs-12 col-sm-4 NanoMarged">
                {/* <span className="emoji">🗝</span> */}
                <h3>Лайфхаки от местных</h3>
                <p>
                  Вы узнаете, как бесплатно подняться на крышу в центре, в каком
                  дворике спрятана необычная мозаика, где пьют вкусный кофе или
                  знатную настойку
                </p>
              </div>
            </div>
            {/* <div className="row between-md">
              <div className="col-xs-12 col-sm-6">
                <span className="emoji">🌟</span>
                <h3>Проверенные экскурсии</h3>
                <p>
                  Наш редактор модерирует каждую экскурсию в магазине
                  и&nbsp;помогает авторам рассказать факты и&nbsp;истории самым
                  интересным образом
                </p>
              </div>
              <div className="col-xs-12 col-sm-6 NanoMarged">
                <span className="emoji">🗝</span>
                <h3>Лайфхаки от местных</h3>
                <p>
                  Вы узнаете, как бесплатно подняться на крышу в центре, в каком
                  дворике спрятана необычная мозаика, где пьют вкусный кофе или
                  знатную настойку
                </p>
              </div>
            </div> */}
          </Section>
          <Section className="violet-section DoubleMarged">
            <div className="row middle-sm between-sm">
              <div className="col-xs-12 col-sm-7 Marged">
                <h2>Интерактивный формат не&nbsp;даст заскучать</h2>
                <p className="lead">
                  Вы будете отвечать на вопросы, которые обратят ваше внимание
                  на интересные детали города. Вы проникнитесь атмосферой и вам
                  будет, что рассказать друзьям
                </p>
                {/* <p className="lead">
                  Вы проникнитесь атмосферой города и вам будет, что рассказать
                  друзьям о ваших находках.
                </p> */}
              </div>
              <div className="col-xs-12 col-sm-5 phone-col phone-col--right self-bottom">
                <Phone className="phone--white-notch-bg">
                  <Screencast
                    src={gifs.question}
                    poster={posters && posters.question}
                  />
                  {/* <Video 
                    src={videos.question} 
                    poster={posters && posters.question} 
                    loop={true}
                  /> */}
                </Phone>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="NanoMarged">
                  <span className="emoji">💁🏻‍♂️</span>
                  <h3>На чилле</h3>
                  <p>Загадки в экскурсиях придуманы, чтобы обратить ваше внимание на интересные детали. Вы не почуствуете себя в игре «Что? Где? Когда?»</p>
                </div>
              </div>
            </div> */}
          </Section>
          <Section>
            <div className="row between-sm">
              <div className="col-xs-12">
                <div className="social Marged NanoMarged--xs">
                  <span
                    style={{
                      position: 'relative',
                      top: '-20px',
                      fontSize: '48px',
                    }}
                  >
                    <span
                      className="emoji"
                      data-emoji="woman-raising-hand-type-1"
                      role="img"
                    >
                      🙋🏻‍♀️
                    </span>
                    <span
                      className="emoji"
                      data-emoji="man-raising-hand-type-1"
                      role="img"
                    >
                      🙋🏻‍♂️
                    </span>
                    <span
                      className="emoji"
                      data-emoji="woman-raising-hand-type-3"
                      role="img"
                    >
                      🙋🏼‍♀️
                    </span>
                  </span>
                  <h2>1200 туристов уже используют Surprise Me</h2>
                  <h2>
                    <span style={{ fontWeight: '500' }}>
                      4,75 ⭐️ — средняя оценка экскурсии. Проверяем каждую
                      экскурсию лично и убираем маршруты с плохими отзывами
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            {reviews ? (
              <div className="wrapped">
                <h2 className="NanoMarged">Отзывы путешественников</h2>
                <div className="row">
                  {reviews.map((review, i) => (
                    <div key={i} className={cn('col-xs-12 col-sm-6')}>
                      <Review {...review} />
                    </div>
                  ))}
                  {/* <h2 className="col-xs-12 col-sm-6">
                    <Button 
                      theme="yellow" 
                      href="https://surprizeme.ru/all-reviews/"
                      style={{display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                      >
                      Все отзывы
                    </Button>
                  </h2> */}
                </div>
              </div>
            ) : null}
          </Section>
          {children}
        </Wrapper>
      </Layout>
    )
  }
}

export default IndexPage
