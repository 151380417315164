import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cn from '../../utils/classnames'
import isInViewport from '../../utils/isInViewport'
import debounce from '../../utils/debounce'
import Loader from './loader'
import './../mixins.css'
import './video.css'

const nearestEvenInt = number => {
  return 2 * Math.round(number / 2)
}

const getPixelPos = (element, percent, { dimension = 'width' }) => {
  const propName = `client${dimension.slice(0, 1).toUpperCase() +
    dimension.slice(1)}`
  return element ? nearestEvenInt(element[propName] * percent) : null
}

// Think about autoplay provider
class Video extends Component {
  state = {
    loading: false,
    loaded: false,
    playing: false,
    paused: false,
    ended: false,
    error: null,
    progress: 0,
  }

  componentDidMount() {
    const {
      video,
      props: { scrollAutoplay = true },
    } = this
    if (scrollAutoplay) {
      this.debouncedListener = debounce(this.autoplayOnVisible, 100)
      window.addEventListener('scroll', this.debouncedListener)
    }
    if (scrollAutoplay) this.autoplayOnVisible()
    // TODO: think about fullscreen
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.debouncedListener)
  }

  autoplayOnVisible = () => {
    const {
      video,
      state: { loading, paused, playing },
    } = this
    if (!isInViewport(video) || paused || playing || loading) return
    this.play(video)
  }

  play = () => {
    const { video } = this
    this.setState(prev => ({ ...prev, loading: true }))
    if (video.readyState !== 4) {
      video.addEventListener('canplaythrough', () => {
        this.setState(prev => ({ ...prev, loaded: true, loading: false }))
        video
          .play()
          .then(() => {
            this.setState(prev => ({
              ...prev,
              playing: true,
              ended: false,
              progress: 0.05,
            }))
          })
          .catch(error => {
            console.log("Can't play video due to error", error)
          })
      })
      video.load()
    } else {
      this.setState(prev => ({ ...prev, loaded: true, loading: false }))
      video.play().then(() => {
        this.setState(prev => ({ ...prev, playing: true, ended: false }))
      })
    }
    // TODO: handle errors
  }

  pause = () => {
    if (!this.video) return
    this.video.pause()
    this.setState(prev => ({ ...prev, playing: false, paused: true }))
  }

  end = () => {
    this.setState(prev => ({ ...prev, playing: false, ended: true }))
  }

  togglePlay = () => {
    if (this.state.playing) {
      // Pause
      this.pause()
    } else {
      // Play
      this.play()
    }
  }

  render() {
    const { src, poster, contain, ...props } = this.props
    const { playing, ended, paused, loading, loaded, progress } = this.state
    return (
      <div className="video">
        <video
          ref={el => (this.video = el)}
          className={cn(
            'video__video',
            playing && 'is-playing',
            ended && 'is-ended',
            contain && 'video__video--contain'
          )}
          onEnded={this.end}
          onClick={this.pause}
          onError={({ target }) => {
            this.setState(prev => ({
              ...prev,
              playing: false,
              error: target.error,
            }))
          }}
          onTimeUpdate={() => {
            const {
              video: { duration, currentTime },
            } = this
            const progress = !duration ? 0 : currentTime / duration
            this.setState(prev => ({ ...prev, progress }))
          }}
          preload="auto"
          muted
          playsInline
          {...props}
        >
          <source src={src} type="video/mp4" />
          <p>Your browser does not support HTML5 video.</p>
        </video>
        {!loaded && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${poster})`,
              backgroundSize: 'cover',
            }}
          />
        )}
        {(!loading || paused) && (
          <div
            className={cn('video__play', ended && 'video__play--repeat')}
            onClick={this.togglePlay}
          />
        )}
        {loading && <Loader />}
        <div
          className="video__progress"
          style={{
            bottom: getPixelPos(this.video, 0.02, { dimension: 'height' }),
          }}
        >
          <div
            className="video__progress-bar"
            style={{
              width: `${progress * 100}%`,
            }}
          />
        </div>
      </div>
    )
  }
}

Video.propTypes = {
  src: PropTypes.string,
}

export default Video
