import PropTypes from 'prop-types'
import React from 'react'

const Wrapper = ({ children, className="", ...props }) => (
  <div
    className={`wrapper ${className}`}
    {...props}
  >
    {children}
  </div>
)

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Wrapper
