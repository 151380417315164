// https://stackoverflow.com/questions/17733076/smooth-scroll-anchor-links-without-jquery
export default function animate(elem, style, unit, from, to, time, prop) {
  if (!elem) {
      return;
  }
  var start = new Date().getTime(),
      timer = setInterval(function () {
          var step = Math.min(1, (new Date().getTime() - start) / time);
          if (prop) {
              elem[style] = (from + step * (to - from))+unit;
          } else {
              elem.style[style] = (from + step * (to - from))+unit;
          }
          if (step === 1) {
              clearInterval(timer);
          }
      }, 25);
  if (prop) {
      elem[style] = from+unit;
  } else {
      elem.style[style] = from+unit;
  }
}

export const smoothscroll = (element, time) => {
  animate(document.scrollingElement || document.documentElement, "scrollTop", "", window.scrollY, element.offsetTop, time, true);
};