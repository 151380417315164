import opening from '../video/saint-petersburg/Opening.mp4';
import geo from '../video/saint-petersburg/Geo.mp4';
import geoGif from '../video/saint-petersburg/Geo.gif';
import question from '../video/saint-petersburg/Question.mp4';
import questionGif from '../video/saint-petersburg/Question.gif';
import geoPoster from '../video/saint-petersburg/Geo-poster.png';
import questionPoster from '../video/saint-petersburg/Question-poster.png';

export default {
    videos: {
      opening,
      geo,
      question
    },
    gifs: {
      geo: geoGif,
      question: questionGif,
    },
    posters: {
      geo: geoPoster,
      question: questionPoster,
    }
  }