import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cn from '../../utils/classnames';
import isInViewport from '../../utils/isInViewport';
import debounce from '../../utils/debounce';
import Loader from '../Video/loader';

// Think about autoplay provider
class Screencast extends Component {
  state = {
    loading: false,
    loaded: false,
    showed: false,
    error: null,
  }

  componentDidMount() {
    const { showOnVisible=true } = this.props;
    if (showOnVisible) {
      this.debouncedListener = debounce(this.showOnVisible, 100);
      window.addEventListener("scroll", this.debouncedListener);
    }

    if (showOnVisible) this.showOnVisible();
  }

  componentWillUnmount() {
    this.removeListener();
  }

  removeListener = () => {
    window.removeEventListener("scroll", this.debouncedListener);
  }

  load = () => {
    const { src } = this.props;

    this.gif = new Image();
    
    this.gif.onload = () => {
      this.setState(prev => ({...prev, showed: true, loaded: true, loading: false}));
      this.removeListener();
    };

    this.gif.onerror = () => {
      this.setState(prev => ({...prev, showed: true, loaded: false, loading: false}));
    };
    
    this.setState(prev => ({...prev, loading: true}));
    this.gif.src = src;
  }

  showOnVisible = () => {
    const { 
      wrapper,
      state: { showed, loading }, 
    } = this;
    if (!isInViewport(wrapper) || showed || loading) return;
    this.setState(prev => ({...prev, loading: true}));
    this.load();
  }

  render() {
    const { src, poster, ...props } = this.props;
    const { showed, loading } = this.state;
    return (
      <div ref={el => this.wrapper = el} {...props}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${poster})`,
          backgroundSize: "cover",
        }}></div>
        {loading && <Loader />}
        {showed && (
          <img style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: "100%",
            }}
            src={src} 
          />
        )}
      </div>
    );
  }
} 

Screencast.propTypes = {
  src: PropTypes.string,
  poster: PropTypes.string,
}

export default Screencast;
