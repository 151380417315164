import React from 'react'
import cn from '../../utils/classnames';
import './review.css';

export default ({avatar, product, name, text}) => {
  return (
      <div className="review-v2 Marged">
        <header className="row middle-xs review-v2__header NanoMarged">
          <div className="col-xs review-v2__avatar-wrapper">
              <div
                className="review-v2__avatar"
                style={{backgroundImage: `url(${avatar})`}}
              ></div>
          </div>
          <div className="col-xs review-v2__title">
            <div className="review-v2__heading">
              <span className="review-v2__name">
                {name}, <a target="_blank" className="black review-v2__product" href={product.link}>{product.name}</a>
              </span>
            </div>
          </div>
        </header>
        <p className={cn("review-v2__text", text.length > 1500 && "review-v2__text--clipped")}>
          {text}
        </p>
      </div>
  )
};