import PropTypes from 'prop-types'
import React from 'react'
import Section from './section'
import appIcon from '../images/logo-ios.png'

const Header = ({ siteTitle, theme, children, withIcon, ...props }) => (
  <Section className="Marged NanoMarged--xs header" {...props}>
    <h3 className={theme}>
      {withIcon ? (
        <img
          src={appIcon}
          class
          style={{
            width: '24px',
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: '0.5em',
          }}
        />
      ) : null}
      <span
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          // fontWeight: 600,
          letterSpacing: '0.3px',
          // fontFamily: "Helvetica Neue, Arial, sans-serif",
        }}
      >
        {siteTitle}
      </span>
    </h3>
    {children}
  </Section>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
