import live from './live';
import { smoothscroll } from './animate';

const selector = "[data-scroll]";

// Inspired by https://github.com/cferdinandi/smooth-scroll/blob/master/src/js/smooth-scroll.js
export default () => {
  live(selector, "click", (event) => {
    // Don't run if right-click or command/control + click
    if (event.button !== 0 || event.metaKey || event.ctrlKey) return;

    // Check if event.target has closest() method
    if(!('closest' in event.target)) return;

    const toggle = event.target.closest(selector);
    if (!toggle) return;

    const hash = toggle.hash;
    const element = document.querySelector(hash);

    if (!element) return;

    event.preventDefault();
    smoothscroll(element, 300);

    window.location.hash = hash;
  });
};