//https://plainjs.com/javascript/events/live-binding-event-handlers-14/

function addEvent(el, type, handler) {
  if (el.attachEvent) el.attachEvent('on'+type, handler); else el.addEventListener(type, handler);
}

export default function live(selector, event, callback, context=document) {
  addEvent(context || document, event, function(e) {
      var found, el = e.target || e.srcElement;
      while (el && el.matches && el !== context && !(found = el.matches(selector))) el = el.parentElement;
      if (found) callback.call(el, e);
  });
}